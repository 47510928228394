import React from "react";
import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
  return (
    <footer className="h-30 bg-cyan-600 pt-1">
      <div className="w-5/6 mx-auto">
        <SocialMediaIcons />
        <div className="md:flex justify-center md:justify-between text-center">
          <p className="font-playfair font-semibold text-2xl text-yellow">
            {" "}
            ANTENH KASSE
          </p>
          <p className="font-playfair text-xl  text-yellow">
            {" "}
            2024 . All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
